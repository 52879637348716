.datePickerFormItem {
	width: 100%;
	:global(.ant-form-item-label) {
		text-align: start !important;
	}
}

.datePicker {
	width: 100%;
}
