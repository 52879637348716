@import "../../common/styles/colours.scss";

.generalStatisticsWrapper {
	:global(.ant-statistic-title) {
		font-weight: 600;
		font-size: 16px !important;
	}

	:global(.ant-statistic-content-value) {
		font-weight: 700;
		font-size: 20px;
	}
}

.generalStatisticsScrollMenu {
	overflow: hidden;
	padding: 0px 16px;

	@extend .generalStatisticsWrapper;
}

.generalStatisticsScrollMenuWrapper {
	button:not([disabled]) {
		svg {
			path {
				fill: $red !important;
			}
		}
	}
}

.spinner {
	background-color: transparent !important;

	:global(.ant-spin-dot-item) {
		background-color: $red !important;
	}
}
