﻿@import "./colours.scss";

.fullSize {
	width: 100%;
	height: 100%;
}

.fullHeight {
	height: 100%;
}

.fullWidth {
	width: 100%;
}

.ant-card.criclebox {
	box-shadow: 0px 20px 27px #0000000d;
	border-radius: 12px;
}
