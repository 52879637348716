@import "../../../common/styles/common.scss";
@import "../dashboard/Dashboard.module.scss";

.chargePointWrapper {
	height: 100%;
	width: 100%;
	position: relative;
	min-height: 240px;

	.statusIconWrapper {
		position: absolute;
		right: 50%;
		top: 16px;
		transform: translate(50%, -50%);

		:global(.ant-badge) {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		:global(.ant-badge-status-text) {
			font-size: 24px;
			font-weight: 500;

			@media (max-width: 996px) {
				font-size: 16px;
			}
		}

		:global(.ant-badge-status-dot) {
			width: 16px;
			height: 16px;

			@media (max-width: 996px) {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.chargePointIconWrapper {
	svg {
		height: 100%;
		display: block;
		width: 100%;
		position: absolute;
		fill: darkseagreen;
	}
}

.chargePointActionsWrapper {
	position: absolute;
	right: 50%;
	transform: translate(50%, -50%);
	bottom: -10px;

	svg {
		height: auto;
		width: auto;
	}
}

.chargePointQrWrapper {
	position: absolute;
	left: 34%;
	top: 28%;

	svg {
		height: auto;
		width: auto;
		fill: black;
	}
}

.chargePointQrWrapper:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.connectorIconWrapper {
	border: 1px dashed black;
	border-radius: 10px;
	padding: 8px;
	max-height: 100px;
	max-width: 100px;
	min-height: 20px;
	min-width: 20px;
	cursor: pointer;
	margin: auto;

	svg {
		display: block;
		height: 100%;
		width: 100%;
	}
}

.connectorIconWrapper:hover {
	transform: scale(1.1);
}

.Available {
	svg {
		fill: green;
	}
}

.Occupied {
	svg {
		fill: orange;
	}
}

.Faulted,
.Unavailable {
	svg {
		fill: red;
	}
}

.chargerWrapper {
	padding: 24px 0px;
	background: white;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	@extend .fullHeight;
}

.tabs {
	:global(.ant-tabs-content),
	:global(.ant-tabs-tabpane) {
		height: 100%;
	}

	:global(.ant-tabs-content) {
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.chargerDescription {
	th:global(.ant-descriptions-item) {
		padding-bottom: 4px !important;
	}
}

.editFloatBtn {
	top: 74px;
	width: 48px;
	height: 48px;
}

.pinkFloatBtn {
	top: 74px;
	width: 48px;
	height: 48px;
	right: 80px;
}

.mapIcon {
	background-color: white;
	border-radius: 5px;
}
