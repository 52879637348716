@import "./common.scss";
@import "./colours.scss";

.number span {
	font-weight: 600;
	color: #8c8c8c;
	font-size: 14px;
}
.number h3 {
	font-weight: 700;
	margin-bottom: 0px;
	font-size: 30px;
}

.number h3 small {
	font-weight: 600;
	font-size: 14px;
}

.icon-box {
	width: 48px;
	height: 48px;
	cursor: pointer;
	text-align: center;
	background: $red;
	color: #fff;
	border-radius: 0.5rem;
	margin-left: auto;
	line-height: 55px;

	justify-content: center;
	align-items: center;
	display: flex;

	svg {
		width: 28px;
		height: 28px;
		path {
			fill: #fff !important;
		}
	}
}

.icon-box span {
	color: #fff;
	font-size: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

.bnb2 {
	color: #0088fe !important;
	font-weight: 700;
}

.layout-content {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.generalStatisticsWrapper {
	:global(.ant-statistic-title) {
		font-weight: 600;
	}

	:global(.ant-statistic-content-value) {
		font-weight: 700;
	}
}

.transactionsChartCard {
	height: 100% !important;
	width: 100% !important;
	:global(.ant-card-body),
	:global(.ant-spin-nested-loading),
	:global(.ant-spin-container) {
		height: 100% !important;
		width: 100% !important;
	}
}

.normalFilterIcon {
	font-size: 18px;
}

.activeFilterIcon {
	@extend .normalFilterIcon;
	path {
		fill: $red !important;
	}
}
