@import "../../common/styles/common.scss";

.baseModal {
	:global(.ant-modal-content) {
		padding: 0px;
	}

	:global(.ant-modal-header) {
		border-bottom: none;
		padding: 32px 32px 0px 32px;
		margin-bottom: 0px;

		:global(.ant-modal-title) {
			h2 {
				margin-bottom: 0px;
			}
		}
	}

	:global(.ant-modal-body) {
		padding: 24px 32px;
	}

	:global(.ant-modal-footer) {
		border-top: 1px solid #e7eaf0;
		padding: 24px 32px;
	}

	@media (min-width: 576px) and (max-width: 767px) {
		width: 75% !important;

		:global(.ant-modal-header) {
			padding: 24px 24px 0px 24px;
		}

		:global(.ant-modal-body),
		:global(.ant-modal-footer) {
			padding: 16px 24px;
		}
	}

	@media (max-width: 575px) {
		width: 95% !important;

		:global(.ant-modal-header) {
			padding: 16px 16px 0px 16px;
		}

		:global(.ant-modal-body),
		:global(.ant-modal-footer) {
			padding: 16px;
		}
	}

	.cancelBtn {
		color: red//$red;
	}
}
