.top-menu {
	:global(.ant-menu-item-selected),
	:global(.ant-menu-submenu-selected) {
		background-color: transparent !important;
		color: #f4ce14;

		:global(.ant-menu-submenu-title) {
			position: unset;
		}

		:global(.ant-menu-title-content)::before {
			content: " ";
			display: inline-block;
			background-image: url("../assets/bolt.svg");
			height: 24px;
			width: 24px;
			position: absolute;
			top: 50%;
			left: 16px;
			transform: translate(-50%, -50%);
		}
	}

	:global(.ant-menu-title-content) {
		padding: 0px 8px;
	}
}

.layout {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}
