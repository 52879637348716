@import "../../common/styles/dashboard.scss";

.transactionChartStatistics {
	:global(.ant-statistic-title) {
		font-weight: 600;
		margin-bottom: 0px;
		font-size: 12px !important;
	}

	:global(.ant-statistic-content-value) {
		font-weight: 600;
		font-size: 20px;
	}
}
